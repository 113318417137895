import { Observable } from 'rxjs';
import {
  PendingCreateDto,
  PendingDto,
  PendingEditDto,
  PendingEditStateDto,
  PendingPageQueryParamsDto,
  PendingPageDto,
  PendingShowDto,
} from '../dtos/pending.dto';

export abstract class PendingRepository {
  abstract getAll(params: any): Observable<PendingDto[]>;
  abstract getPage(
    params: PendingPageQueryParamsDto
  ): Observable<PendingPageDto>;
  abstract create(user: PendingCreateDto): Observable<PendingDto>;
  abstract update(userId: PendingEditDto): Observable<PendingDto>;
  abstract updateState(userId: PendingEditStateDto): Observable<PendingDto>;
  abstract show(userId: number): Observable<PendingShowDto>;
}
